<template>
  <div v-if="items?.length" class="swiping relative">
    <Swiper
      :modules="[
        SwiperNavigation,
        SwiperPagination,
        SwiperA11y,
        SwiperAutoplay,
      ]"
      :centeredSlides="swiperOptions.centeredSlides"
      @swiper="onSwiperLoad"
      :autoplay="swiperOptions.autoplay"
      :breakpoints="swiperOptions.breakpoints"
      :pagination="swiperOptions.pagination"
      :loop="swiperOptions.loop"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="item?.id || index"
        v-slot="{isActive}"
      >
        <slot :item="item" :isActive="isActive" />
      </swiper-slide>
    </Swiper>

    <div
      v-show="arrows"
      class="swiping-navigation md:absolute top-0 start-0 w-full h-full flex items-center md:justify-between gap-x-3 mt-10 md:mt-0"
    >
      <button class="relative z-10 swiping-prev" @click="onPrevSlide()">
        <svg-icon name="chevron-right" class="fill-current w-3 h-3" />
      </button>
      <button class="relative z-10 swiping-next" @click="onNextSlide()">
        <svg-icon name="chevron-left" class="fill-current w-3 h-3" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  arrows?: boolean;
  dotted?: boolean;
  swiperOptions?: object;
  items: any[];
}

const props = withDefaults(defineProps<Props>(), {
  arrows: false,
  dotted: false,
});

const swiperOptions = computed(() => {
  return {
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      740: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    ...(props.dotted && {
      pagination: {
        clickable: true,
      },
    }),
    loop: false,
    centeredSlides: false,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
    ...props?.swiperOptions,
  };
});

const swiper = ref();

const onSwiperLoad = (value: any) => {
  swiper.value = value;
};

const onNextSlide = () => {
  swiper.value?.slideNext();
};

const onPrevSlide = () => {
  swiper.value?.slidePrev();
};
</script>

<style lang="postcss">
.swiping {
  &-next,
  &-prev {
    @apply w-10 h-10 rounded-full flex items-center justify-center text-white bg-sky-blue;
    &.disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }

  .swiper {
    &-pagination {
      @apply relative pt-10;
      &-bullet {
        @apply w-3 h-1.5 bg-sky-400/50 rounded-md opacity-100 transition-all duration-150;
        &-active {
          @apply w-10 bg-sky-400;
        }
      }
    }
  }
}
</style>
